import React from 'react';
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useTranslation } from "react-i18next";

export default function LanguageSelector() {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const languages = [
    { code: 'en', label: 'English', flag: 'gb' }, // gb for English
    { code: 'fr', label: 'Français', flag: 'fr' }, // fr for French
  ];

  const currentLanguage = i18n.language;

  // Toggle menu
  const handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang); // Change language
    setAnchorEl(null); // Close menu
  };

  return (
    <>
      {/* Language Selector Button */}
      <IconButton
        size="md"
        variant="outlined"
        color="neutral"
        onClick={handleToggleMenu} // Toggle the menu on click
        sx={{
          display: { xs: 'none', sm: 'inline-flex' },
          borderRadius: '50%',
        }}
      >
        <LanguageRoundedIcon />
      </IconButton>

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)} // Open when anchorEl is set
        onClose={() => setAnchorEl(null)} // Close menu
        placement="bottom-start"
        sx={{ zIndex: 1000, mt: '1rem' }}
      >
        {languages.map((lang) => (
          <MenuItem
            key={lang.code}
            onClick={() => handleLanguageChange(lang.code)}
            selected={lang.code === currentLanguage}
          >
            {/* Flag using flagcdn */}
            <ListItemDecorator>
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${lang.flag}.png 2x`}
                src={`https://flagcdn.com/w20/${lang.flag}.png`}
                alt={`${lang.label} flag`}
              />
            </ListItemDecorator>
            {lang.label}
            {/* Check icon for selected language */}
            {lang.code === currentLanguage && (
              <ListItemDecorator sx={{ ml: 'auto' }}>
                <CheckRoundedIcon />
              </ListItemDecorator>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}