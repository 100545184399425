const contact_us_fr = {
    contact_us_title: "Contactez-nous",
    form: {
        firstName: "Prénom",
        lastName: "Nom de famille",
        phoneNumber: "Numéro de téléphone",
        email: "E-mail",
        enquiryRelatedTo: "Objet de la demande",
        enquiryOptions: {
            general: "Demande générale",
            support: "Support technique",
            feedback: "Retour d'expérience",
            collaboration: "Collaboration",
            other: "Autre",
        },
        message: "Message",
        submitButton: "Envoyer",
    },
};

export default contact_us_fr;