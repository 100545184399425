import { onCLS, onFID, onFCP, onLCP, onTTFB } from 'web-vitals';const reportWebVitals = (onPerfEntry: (metric: { name: string; value: number }) => void) => {
  if (onPerfEntry && typeof onPerfEntry === 'function') {
    onCLS((metric) => onPerfEntry({ name: 'CLS', value: metric.value }));
    onFID((metric) => onPerfEntry({ name: 'FID', value: metric.value }));
    onFCP((metric) => onPerfEntry({ name: 'FCP', value: metric.value }));
    onLCP((metric) => onPerfEntry({ name: 'LCP', value: metric.value }));
    onTTFB((metric) => onPerfEntry({ name: 'TTFB', value: metric.value }));
  }
};

export default reportWebVitals;