import React from 'react';
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Textarea from "@mui/joy/Textarea";
import { useTranslation } from "react-i18next";
import Input from "@mui/joy/Input";

export default function ContactUs() {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                p: 4,
                border: '1px solid',
                borderColor: 'neutral.outlinedBorder',
                borderRadius: 'md',
                boxShadow: 'sm',
            }}
        >
            <form>
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mb: 2 }}>
                    <Input
                        placeholder={t('form.firstName')}
                        required
                        sx={{ flex: '1 1 calc(50% - 8px)' }}
                    />
                    <Input
                        placeholder={t('form.lastName')}
                        required
                        sx={{ flex: '1 1 calc(50% - 8px)' }}
                    />
                </Box>
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mb: 2 }}>
                    <Input
                        placeholder={t('form.phoneNumber')}
                        type="tel"
                        required
                        sx={{ flex: '1 1 calc(50% - 8px)' }}
                    />
                    <Input
                        placeholder={t('form.email')}
                        type="email"
                        required
                        sx={{ flex: '1 1 calc(50% - 8px)' }}
                    />
                </Box>
                <Box sx={{ mb: 2 }}>
                    <Select
                        placeholder={t('form.enquiryRelatedTo')}
                        required
                        sx={{ minWidth: '100%' }}
                    >
                        <Option value="general">{t('form.enquiryOptions.general')}</Option>
                        <Option value="support">{t('form.enquiryOptions.support')}</Option>
                        <Option value="feedback">{t('form.enquiryOptions.feedback')}</Option>
                        <Option value="feedback">{t('form.enquiryOptions.collaboration')}</Option>
                        <Option value="other">{t('form.enquiryOptions.other')}</Option>
                    </Select>
                </Box>
                <Box sx={{ mb: 2 }}>
                    <Textarea
                        minRows={4}
                        placeholder={t('form.message')}
                        required
                        sx={{ width: '100%' }}
                    />
                </Box>
                <Button variant="solid" type="submit">
                    {t('form.submitButton')}
                </Button>
            </form>
        </Box>
    );
}