const consent_form_en = {
    // Hero section main content
    header_message: "The power to do more",
    invitation_message: "Join the Revolution of Global Storytelling on PanComix",
    signUp: "Sign up below to be the first to know when we launch",
    introduction: "Hello, we are PanComix, a vibrant platform designed to connect comics creators with a global audience of comic enthusiasts. Our mission is to empower artists to showcase their unique stories and art, while providing fans with access to a diverse library of independent comics. By fostering a supportive community, we aim to redefine the way comics are shared, discovered, and enjoyed. Whether you're an artist with a captivating story to share or a reader eager to explore diverse narratives, PanComix is your gateway to a world of unique comics that transcend borders. We invite creators to showcase their rich, vibrant tales to a global audience, while readers can discover the untold stories from various cultures. Sign up below to be the first to know when we launch and embark on this exciting journey with us. Together, let's elevate diverse stories to the world stage!",
    thank_you_message: "Thank you for joining our waiting list!",
    registration_confirmation_message: "We will notify you when you need to confirm your registration.",

    // Consent form
    consent_title: "Informed Consent Form",
    consent_text_old: "BACKGROUND AND PURPOSE: PanComix is an online platform that connects independent comics creators with readers. The purpose of this survey is to gather insights from both artists and readers to help improve the platform and offer services that best suit your needs. " +
        "PROCEDURES: You will be asked to complete a short survey regarding your interest in the platform, the type of comics you are interested in, and your preferences as an artist or reader." +
        " VOLUNTARY PARTICIPATION: Participation in this survey is completely voluntary, and you may withdraw at any time without penalty. " +
        "CONFIDENTIALITY: All information collected in this survey will be kept confidential and used solely for the purposes of improving the platform. " +
        "RISKS: There are no foreseeable risks involved in participating in this survey. " +
        "REWARDS: No monetary compensation will be provided for completing this survey. " +
        "COSTS: There are no costs involved in participating in this survey. " +
        "BENEFITS: Your participation will help us develop a better platform that meets the needs of both comics creators and fans. " +
        "CONSENT: By clicking the checkbox below, you indicate that you have read and understood the information above and consent to participate in this survey.",
    consent_text: {
        background: "PanComix is an online platform that connects independent comics creators with readers. The purpose of this survey is to gather insights from both artists and readers to help improve the platform and offer services that best suit your needs.",
        procedures: "You will be asked to complete a short survey regarding your interest in the platform, the type of comics you are interested in, and your preferences as an artist or reader.",
        voluntary_participation: "Participation in this survey is completely voluntary, and you may withdraw at any time without penalty.",
        confidentiality: "All information collected in this survey will be kept confidential and used solely for the purposes of improving the platform.",
        risks: "There are no foreseeable risks involved in participating in this survey.",
        rewards: "No monetary compensation will be provided for completing this survey. However, participants will always gain first access to new features, premium and beta features, and other rewards to be introduced in the future.",
        costs: "There are no costs involved in participating in this survey.",
        benefits: "Your participation will help us develop a better platform that meets the needs of both comics creators and fans.",
        consent: "By clicking the checkbox below, you indicate that you have read and understood the information above and consent to participate in this survey."
    },
    consent_checkbox_label: "I have read and agree to the informed consent form.",

    // Common form elements
    user_type_label: "User Type",
    user_type_artist: "Artist",
    user_type_reader: "Reader",
    additional_suggestions_label: "Additional suggestions or comments",

    // Artist-specific questions
    artist_community_question: "Are you interested in building your community on our platform and earning extra income?",
    publishing_frequency_label: "How often are you comfortable publishing?",
    publishing_frequency_weekly: "Weekly",
    publishing_frequency_monthly: "Monthly",
    publishing_frequency_other: "Other",

    income_goal_label: "What is your monthly income goal from the platform?",
    income_goal_100: "< $100",
    income_goal_100_500: "$100-$500",
    income_goal_500_1000: "$500-$1000",
    income_goal_1000: "> $1000",

    comic_type_label: "What type of comics do you create?",
    comic_type_manga: "Manga",
    comic_type_graphic_novels: "Graphic Novels",
    comic_type_webcomics: "Webcomics",
    comic_type_others: "Others",
    comic_type_specify: "Specify",

    monetization_interest_label: "Would you be interested in having your works monetized on the platform?",
    artist_features_label: "What type of support or features would you like from PanComix?",

    artist_experience_label: "What is your level of experience as an artist?",
    experience_beginner: "Beginner",
    experience_intermediate: "Intermediate",
    experience_expert: "Expert",

    // Reader-specific questions
    reader_interest_question: "As a comics enthusiast, would you be interested in joining our platform?",
    reader_genres_label: "What genres are you most interested in?",
    genre_action: "Action",
    genre_adventure: "Adventure",
    genre_comedy: "Comedy",
    genre_drama: "Drama",
    genre_fantasy: "Fantasy",
    genre_horror: "Horror",
    genre_romance: "Romance",
    genre_scifi: "Sci-Fi",
    genre_other: "Other",

    reader_support_question: "Would you be interested in supporting artists directly?",
    reader_subscription_question: "Are you interested in supporting creators through subscriptions?",
    reading_frequency_label: "How often would you like to read new comics?",
    reading_frequency_weekly: "Weekly",
    reading_frequency_monthly: "Monthly",

    subscription_amount_label: "How much would you like to pay for a subscription?",
    subscription_1_5: "$1 - $5",
    subscription_6_10: "$6 - $10",
    subscription_11_20: "$11 - $20",
    subscription_others: "Others",
    subscription_amount_specify: "Please specify amount",

    reader_features_label: "What features would encourage you to read more comics on PanComix?",

    // Personal info fields
    first_name_placeholder: "First Name",
    surname_placeholder: "Surname",
    email_placeholder: "Email",

    // Common responses
    yes: "Yes",
    no: "No",

    // Submit button
    submit_button: "Submit",

    // Error messages
    consent_required: "You must accept the consent form to submit.",
    submission_failed: "Submission failed: "
};

export default consent_form_en;