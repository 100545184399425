const about_us_en = {
  about: {
    title: 'About Pan Comix',
    description: `
      Pan Comix is a global platform that empowers independent comics artists to share their stories 
      with readers across the world. We are committed to building a thriving creative ecosystem where 
      artists can showcase their talent, reach new audiences, and earn revenue for their work. 
      By combining accessibility, quality storytelling, and community support, we aim to redefine how 
      indie comics are discovered and enjoyed.
    `,
    highlights: [
      {
        title: 'For Creators',
        content: `
          Pan Comix provides artists with an easy-to-use platform to publish their comics, manage their 
          audience, and earn income through a freemium model. Creators can share their work for free 
          initially, with options to monetize after a trial period. We believe that artists deserve fair 
          compensation for their creativity, and we make this possible by connecting them directly 
          with readers through transparent and accessible subscription packages.
        `,
      },
      {
        title: 'For Readers',
        content: `
          Readers can discover a vast library of indie comics from creators worldwide. 
          We offer a trial period during which readers can explore content for free. After the trial, 
          readers can continue supporting their favorite artists by subscribing to affordable packages. 
          This not only ensures that creators are rewarded for their work but also allows readers to 
          enjoy uninterrupted access to amazing stories, unique art, and fresh perspectives.
        `,
      },
    ],
    contactButton: 'Contact Us',
  },
  faq: {
    title: 'Frequently Asked Questions',
    questions: [
      {
        question: 'How does Pan Comix work?',
        answer: `
          Pan Comix connects readers and creators through a digital comics platform. Artists can upload 
          their comics for readers to explore. Initially, readers have free access through a trial 
          period, after which they can subscribe to a package to continue enjoying the content. This 
          system helps creators monetize their work while readers gain access to a vibrant library of stories.
        `,
      },
      {
        question: 'How do creators earn money on Pan Comix?',
        answer: `
          Pan Comix operates on a freemium subscription model. After a trial period, readers subscribe 
          to access premium content. A portion of the subscription revenue goes directly to the creators, 
          ensuring they are fairly compensated for their hard work and creativity.
        `,
      },
      {
        question: 'What types of comics can I find on Pan Comix?',
        answer: `
          Our platform showcases a wide variety of indie comics, from action and fantasy to drama, 
          romance, and slice-of-life. Whether you’re looking for fresh, unique narratives or bold 
          artistic styles, Pan Comix offers something for everyone.
        `,
      },
      {
        question: 'Is Pan Comix free for readers?',
        answer: `
          Readers can enjoy a free trial period to explore the platform. After the trial ends, 
          a subscription is required to continue accessing premium content. This allows us to 
          support creators and keep delivering high-quality comics to our audience.
        `,
      },
    ],
  },
  contact: {
    title: 'Contact Us',
    description: `
      Have questions or want to collaborate? We’d love to hear from you! Reach out to us at 
      info@pancomix.com or follow us on our social media channels to stay updated on the latest news, 
      comics, and creator spotlights.
    `,
  },
};

export default about_us_en;