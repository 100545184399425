const about_us_fr = {
  about: {
    title: 'À propos de Pan Comix',
    description: `
      Pan Comix est une plateforme mondiale qui permet aux artistes indépendants de bandes dessinées 
      de partager leurs histoires avec des lecteurs du monde entier. Nous nous engageons à créer un 
      écosystème créatif dynamique où les artistes peuvent présenter leurs talents, atteindre de 
      nouveaux publics et générer des revenus grâce à leurs œuvres. En alliant accessibilité, narration 
      de qualité et soutien communautaire, nous redéfinissons la manière dont les bandes dessinées 
      indépendantes sont découvertes et appréciées.
    `,
    highlights: [
      {
        title: 'Pour les créateurs',
        content: `
          Pan Comix offre aux artistes une plateforme facile à utiliser pour publier leurs bandes 
          dessinées, gérer leur audience et générer des revenus grâce à un modèle freemium. Les créateurs 
          peuvent partager leurs œuvres gratuitement au début, avec la possibilité de les monétiser après 
          une période d'essai. Nous croyons que les artistes méritent une rémunération équitable pour 
          leur créativité, et nous rendons cela possible en les connectant directement aux lecteurs via 
          des abonnements transparents et accessibles.
        `,
      },
      {
        title: 'Pour les lecteurs',
        content: `
          Les lecteurs peuvent découvrir une vaste bibliothèque de bandes dessinées indépendantes 
          provenant de créateurs du monde entier. Une période d'essai gratuite permet d'explorer le 
          contenu, après quoi les lecteurs peuvent soutenir leurs artistes préférés en souscrivant à 
          des forfaits abordables. Cela garantit non seulement que les créateurs sont récompensés pour 
          leur travail, mais permet également aux lecteurs de profiter d'un accès continu à des histoires 
          exceptionnelles, des œuvres uniques et des perspectives nouvelles.
        `,
      },
    ],
    contactButton: 'Contactez-nous',
  },
  faq: {
    title: 'Foire Aux Questions',
    questions: [
      {
        question: 'Comment fonctionne Pan Comix ?',
        answer: `
          Pan Comix connecte les lecteurs et les créateurs grâce à une plateforme numérique de bandes 
          dessinées. Les artistes peuvent télécharger leurs œuvres pour que les lecteurs puissent les 
          découvrir. Les lecteurs bénéficient d'une période d'essai gratuite, après laquelle ils peuvent 
          souscrire à un abonnement pour continuer à accéder au contenu. Ce système permet aux créateurs 
          de monétiser leur travail tout en offrant aux lecteurs une riche bibliothèque d’histoires.
        `,
      },
      {
        question: 'Comment les créateurs gagnent-ils de l’argent sur Pan Comix ?',
        answer: `
          Pan Comix utilise un modèle d'abonnement freemium. Après une période d'essai gratuite, 
          les lecteurs souscrivent à des abonnements pour accéder au contenu premium. Une partie 
          des revenus générés par ces abonnements est reversée directement aux créateurs, garantissant 
          une rémunération juste pour leur créativité et leur travail acharné.
        `,
      },
      {
        question: 'Quels types de bandes dessinées trouve-t-on sur Pan Comix ?',
        answer: `
          Notre plateforme propose une grande variété de bandes dessinées indépendantes, allant de 
          l'action et la fantasy au drame, à la romance et à la tranche de vie. Que vous recherchiez 
          des récits inédits ou des styles artistiques audacieux, Pan Comix a quelque chose à offrir 
          à chaque lecteur.
        `,
      },
      {
        question: 'Pan Comix est-il gratuit pour les lecteurs ?',
        answer: `
          Les lecteurs bénéficient d'une période d'essai gratuite pour explorer la plateforme. Une fois 
          la période d'essai terminée, un abonnement est requis pour accéder au contenu premium. 
          Cela nous permet de soutenir les créateurs tout en continuant à offrir des bandes dessinées 
          de haute qualité à notre audience.
        `,
      },
    ],
  },
  contact: {
    title: 'Contactez-nous',
    description: `
      Des questions ou une envie de collaborer ? Nous serions ravis d’échanger avec vous ! 
      Écrivez-nous à info@pancomix.com ou suivez-nous sur nos réseaux sociaux pour rester informé 
      des dernières actualités, bandes dessinées et découvertes de créateurs.
    `,
  },
};

export default about_us_fr;