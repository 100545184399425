const contact_us_en = {
    contact_us_title: "Contact Us",
    form: {
        firstName: "First Name",
        lastName: "Last Name",
        phoneNumber: "Phone Number",
        email: "Email",
        enquiryRelatedTo: "Enquiry Related To",
        enquiryOptions: {
            general: "General Inquiry",
            support: "Technical Support",
            feedback: "Feedback",
            collaboration: "Collaboration",
            other: "Other",
        },
        message: "Message",
        submitButton: "Submit",
    },
};

export default contact_us_en;