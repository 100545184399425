import React from 'react';
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Tooltip from '@mui/joy/Tooltip';
import BookRoundedIcon from '@mui/icons-material/BookRounded';
import Avatar from '@mui/joy/Avatar';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ThemeToggle from "../utils/ThemeToggle";

export default function HeaderMenu() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1.5,
        alignItems: 'center',
      }}
    >
      {/* Search Input */}
      <Input
        size="sm"
        variant="outlined"
        placeholder="Search anything…"
        startDecorator={<SearchRoundedIcon color="primary" />}
        endDecorator={
          <IconButton variant="outlined" color="neutral">
            <Typography level="title-sm">⌘ K</Typography>
          </IconButton>
        }
        sx={{ display: { xs: 'none', sm: 'flex' } }}
      />

      {/* Tooltip and Joy UI Overview */}
      <Tooltip title="Search here ..." variant="outlined">
        <IconButton
          size="sm"
          variant="plain"
          color="neutral"
          component="a"
          href="/blog/first-look-at-joy/"
        >
          <BookRoundedIcon />
        </IconButton>
      </Tooltip>
        <ThemeToggle />

      {/* User Avatar and Dropdown */}
      <Dropdown>
        <MenuButton
          variant="plain"
          size="sm"
          sx={{ maxWidth: '32px', maxHeight: '32px', borderRadius: '50%' }}
        >
          <Avatar src="https://i.pravatar.cc/40?img=2" />
        </MenuButton>
        <Menu>
          <MenuItem>Profile</MenuItem>
          <ListDivider />
          <MenuItem>
            <LogoutRoundedIcon />
            Log out
          </MenuItem>
        </Menu>
      </Dropdown>
    </Box>
  );
}