import React from 'react';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';
import { Navigation } from '@mui/icons-material';
import LanguageSelector from '../utils/LanguageSelector';
import ThemeToggle from '../utils/ThemeToggle';
import HeaderMenu from './HeaderMenu';

interface HeaderProps {
  showSearch?: boolean;
  isAboutPage?: boolean;
}

export default function Header({ showSearch = true, isAboutPage = false }: HeaderProps) {
  const [open, setOpen] = React.useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center', // Align items vertically in the center
        position: 'relative', // Keep the header from expanding vertically
        height: '64px', // Explicit header height
        paddingX: '1rem', // Horizontal padding
        boxShadow: 'sm', // Optional: Add a subtle shadow for visual separation
        backgroundColor: 'background.surface', // Ensure proper background color
        zIndex: 1000, // Stay on top of other components
      }}
    >
      {/* Left Section */}
      <Stack direction="row" spacing={1} alignItems="center">
        <LanguageSelector />
        {!isAboutPage && (
          <Button variant="plain" color="neutral" href="/" size="sm">
            PanComix
          </Button>
        )}
      </Stack>

      {/* Right Section */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ThemeToggle />
        {!isAboutPage && <HeaderMenu />}
      </Box>
    </Box>
  );
}