import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import about_us_en from "./locales/en/about_us";
import about_us_fr from "./locales/fr/about_us";
import contact_us_en from "./locales/en/contact_us";
import contact_us_fr from "./locales/fr/contact_us";
import consent_form_en from "./locales/en/consent_form";
import consent_form_fr from "./locales/fr/consent_form";

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    ...consent_form_en,
                    ...about_us_en,
                    ...contact_us_en
                }
            },
            fr: {
                translation: {
                    ...consent_form_fr,
                    ...about_us_fr,
                    ...contact_us_fr
                }
            }
        }
    });

export default i18n;