const consent_form_fr = {
    // Hero section main content
    header_message: "Le pouvoir de faire plus",
    invitation_message: "Rejoignez la révolution mondiale de la narration sur PanComix",
    signUp: "Inscrivez-vous ci-dessous pour être informé dès notre lancement",
    introduction: "Bonjour, nous sommes PanComix, une plateforme dynamique conçue pour connecter les créateurs de bandes dessinées avec un public mondial d’amateurs de BD. Notre mission est de permettre aux artistes de présenter leurs histoires et œuvres uniques, tout en offrant aux fans l’accès à une bibliothèque diversifiée de bandes dessinées indépendantes. En favorisant une communauté solidaire, nous visons à redéfinir la manière dont les bandes dessinées sont partagées, découvertes et appréciées. Que vous soyez un artiste avec une histoire captivante à partager ou un lecteur désireux d’explorer des récits diversifiés, PanComix est votre passerelle vers un monde de bandes dessinées uniques qui transcendent les frontières. Nous invitons les créateurs à présenter leurs récits riches et vibrants à un public mondial, tandis que les lecteurs peuvent découvrir des histoires inédites issues de diverses cultures. Inscrivez-vous ci-dessous pour être informé dès notre lancement et embarquez avec nous dans cette aventure passionnante. Ensemble, portons des histoires diversifiées sur la scène mondiale !",
    thank_you_message: "Merci de rejoindre notre liste d’attente !",
    registration_confirmation_message: "Nous vous informerons lorsque vous devrez confirmer votre inscription.",

    // Consent form
    consent_title: "Formulaire de consentement éclairé",
    consent_text_old: "CONTEXTE ET OBJECTIF : PanComix est une plateforme en ligne qui connecte les créateurs de bandes dessinées indépendantes avec les lecteurs. Le but de cette enquête est de recueillir des informations auprès des artistes et des lecteurs pour améliorer la plateforme et proposer des services adaptés à vos besoins. " +
        "PROCÉDURES : Vous serez invité à répondre à une courte enquête concernant votre intérêt pour la plateforme, le type de bandes dessinées qui vous intéresse, ainsi que vos préférences en tant qu’artiste ou lecteur." +
        " PARTICIPATION VOLONTAIRE : La participation à cette enquête est entièrement volontaire et vous pouvez vous retirer à tout moment sans pénalité. " +
        "CONFIDENTIALITÉ : Toutes les informations recueillies dans cette enquête resteront confidentielles et seront utilisées uniquement pour améliorer la plateforme. " +
        "RISQUES : Aucun risque prévisible n’est associé à la participation à cette enquête. " +
        "RÉCOMPENSES : Aucune compensation financière ne sera fournie pour compléter cette enquête. " +
        "COÛTS : Aucun coût n’est associé à la participation à cette enquête. " +
        "AVANTAGES : Votre participation nous aidera à développer une plateforme qui répond mieux aux besoins des créateurs et des fans de bandes dessinées. " +
        "CONSENTEMENT : En cliquant sur la case ci-dessous, vous indiquez que vous avez lu et compris les informations ci-dessus et que vous consentez à participer à cette enquête.",
    consent_text: {
        background: "PanComix est une plateforme en ligne qui connecte les créateurs de bandes dessinées indépendantes avec les lecteurs. Le but de cette enquête est de recueillir des informations auprès des artistes et des lecteurs pour améliorer la plateforme et proposer des services adaptés à vos besoins.",
        procedures: "Vous serez invité à répondre à une courte enquête concernant votre intérêt pour la plateforme, le type de bandes dessinées qui vous intéresse, ainsi que vos préférences en tant qu’artiste ou lecteur.",
        voluntary_participation: "La participation à cette enquête est entièrement volontaire, et vous pouvez vous retirer à tout moment sans pénalité.",
        confidentiality: "Toutes les informations recueillies dans cette enquête resteront confidentielles et seront utilisées uniquement pour améliorer la plateforme.",
        risks: "Aucun risque prévisible n’est associé à la participation à cette enquête.",
        rewards: "Aucune compensation financière ne sera fournie pour compléter cette enquête. Cependant, les participants auront toujours un accès prioritaire aux nouvelles fonctionnalités, ainsi qu’aux versions premium et bêta, et d’autres récompenses à venir.",
        costs: "Aucun coût n’est associé à la participation à cette enquête.",
        benefits: "Votre participation nous aidera à développer une plateforme qui répond mieux aux besoins des créateurs et des fans de bandes dessinées.",
        consent: "En cliquant sur la case ci-dessous, vous indiquez que vous avez lu et compris les informations ci-dessus et que vous consentez à participer à cette enquête."
    },
    consent_checkbox_label: "J’ai lu et j’accepte le formulaire de consentement éclairé.",

    // Common form elements
    user_type_label: "Type d’utilisateur",
    user_type_artist: "Artiste",
    user_type_reader: "Lecteur",
    additional_suggestions_label: "Suggestions ou commentaires supplémentaires",

    // Artist-specific questions
    artist_community_question: "Êtes-vous intéressé à créer votre communauté sur notre plateforme et à générer des revenus supplémentaires ?",
    publishing_frequency_label: "À quelle fréquence êtes-vous à l’aise de publier ?",
    publishing_frequency_weekly: "Hebdomadaire",
    publishing_frequency_monthly: "Mensuelle",
    publishing_frequency_other: "Autre",

    income_goal_label: "Quel est votre objectif de revenu mensuel sur la plateforme ?",
    income_goal_100: "< 100 $",
    income_goal_100_500: "100 $ - 500 $",
    income_goal_500_1000: "500 $ - 1000 $",
    income_goal_1000: "> 1000 $",

    comic_type_label: "Quel type de bandes dessinées créez-vous ?",
    comic_type_manga: "Manga",
    comic_type_graphic_novels: "Romans graphiques",
    comic_type_webcomics: "Webcomics",
    comic_type_others: "Autres",
    comic_type_specify: "Précisez",

    monetization_interest_label: "Seriez-vous intéressé à monétiser vos œuvres sur la plateforme ?",
    artist_features_label: "Quels types de soutien ou de fonctionnalités aimeriez-vous de la part de PanComix ?",

    artist_experience_label: "Quel est votre niveau d’expérience en tant qu’artiste ?",
    experience_beginner: "Débutant",
    experience_intermediate: "Intermédiaire",
    experience_expert: "Expert",

    // Reader-specific questions
    reader_interest_question: "En tant qu’amateur de BD, seriez-vous intéressé à rejoindre notre plateforme ?",
    reader_genres_label: "Quels genres vous intéressent le plus ?",
    genre_action: "Action",
    genre_adventure: "Aventure",
    genre_comedy: "Comédie",
    genre_drama: "Drame",
    genre_fantasy: "Fantasy",
    genre_horror: "Horreur",
    genre_romance: "Romance",
    genre_scifi: "Science-fiction",
    genre_other: "Autre",

    reader_support_question: "Souhaitez-vous soutenir directement les artistes ?",
    reader_subscription_question: "Êtes-vous intéressé à soutenir les créateurs via des abonnements ?",
    reading_frequency_label: "À quelle fréquence souhaitez-vous lire de nouvelles bandes dessinées ?",
    reading_frequency_weekly: "Hebdomadaire",
    reading_frequency_monthly: "Mensuelle",

    subscription_amount_label: "Combien seriez-vous prêt à payer pour un abonnement ?",
    subscription_1_5: "1 $ - 5 $",
    subscription_6_10: "6 $ - 10 $",
    subscription_11_20: "11 $ - 20 $",
    subscription_others: "Autres",
    subscription_amount_specify: "Veuillez préciser le montant",

    reader_features_label: "Quelles fonctionnalités vous encourageraient à lire davantage de BD sur PanComix ?",

    // Personal info fields
    first_name_placeholder: "Prénom",
    surname_placeholder: "Nom",
    email_placeholder: "Email",

    // Common responses
    yes: "Oui",
    no: "Non",

    // Submit button
    submit_button: "Envoyer",

    // Error messages
    consent_required: "Vous devez accepter le formulaire de consentement pour soumettre.",
    submission_failed: "Échec de la soumission : "
};

export default consent_form_fr;