import React from 'react';
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/joy";
import {useTranslation} from "react-i18next";
import ContactUs from './ContactUs';
import Header from "../header/Header";

export default function AboutUs() {
    const {t} = useTranslation();
    return (
        <Box>
            <Box sx={{px: 4, py: 6}}>
            {/* About Us Section */}
            <Box sx={{mb: 6}}>
                <Typography level="h2">{t('about.title')}</Typography>
                <Typography level="body-md" sx={{mt: 2}}>
                    {t('about.description')}
                </Typography>
            </Box>

            {/* FAQ Section */}
            <Box sx={{mb: 6}}>
                <Typography level="h3">{t('faq.title')}</Typography>
                <Accordion>
                    <AccordionSummary>{t('faq.questions.0.question')}</AccordionSummary>
                    <AccordionDetails>
                        {t('faq.questions.0.answer')}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary>{t('faq.questions.1.question')}</AccordionSummary>
                    <AccordionDetails>
                        {t('faq.questions.1.answer')}
                    </AccordionDetails>
                </Accordion>
            </Box>

            {/* Contact Us Section */}
            <Box>
                <Typography level="h3" sx={{mb: 2}}>
                    {t('contact_us_title')}
                </Typography>
                <ContactUs/>
            </Box>
        </Box>
        </Box>


    );
}