import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Header from "../components/header/Header";
import AboutUs from "../components/aboutus/AboutUs";
import Box from '@mui/joy/Box';

const AboutUsPage: React.FC = () => {
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      {/* Header: Full-width and at the top */}
      <Header isAboutPage/>

      {/* Main Content below the Header */}
      <Box sx={{
          px: 2, // Horizontal padding (left and right)
          py: 0.5, // Vertical padding (top and bottom)
        }}>
        <AboutUs />
      </Box>
    </CssVarsProvider>
  );
};

export default AboutUsPage;